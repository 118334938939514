import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['input']

  toggle(event) {
    $(this['inputTargets']).each(function(_, el) {
      el.disabled = !event.target.checked
      $(el).trigger('disabled')
    })
  }
}
