import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['origin', 'selected', 'container']

  connect() {
    $(this['selectedTarget']).attr('name', '')
  }

  addItem() {
    if (this['originTarget'].disabled || this['selectedTarget'].disabled) { return }
    const selected = $(this['originTarget']).find('option:selected')
    $(this['selectedTarget']).append(selected)

    const template = $(this['containerTarget']).data('template')
    selected.each((i, option) => {
      const input = $(template).val(option.value)
      $(this['containerTarget']).append(input)
    })
  }

  removeItem() {
    if (this['originTarget'].disabled || this['selectedTarget'].disabled) { return }
    const removed = $(this['selectedTarget']).find('option:selected')
    $(this['originTarget']).append(removed)

    removed.each((i, option) => {
      $(this['containerTarget']).find(`input[value='${option.value}']:first`).remove()
    })
  }
}
