import { Controller } from 'stimulus'
import $ from 'jquery'
import * as _ from 'lodash'

export default class extends Controller {
  static targets = ['container']

  initialize() {
    this.change = this.change.bind(this)
  }

  connect() {
    this['$form'] = $(this['containerTarget']).closest('form')
    this['$form'].on('change', this.change)
    this['$form'].trigger('change')
  }

  disconnect() {
    this['$form'].off('change', this.change)
  }

  change(event) {
    const valueArray = $(event.currentTarget).serializeArray()
    const valuesMapping = _.reduce(valueArray, function(result, item) {
      result[item.name] = result[item.name] || []
      result[item.name].push(item.value)
      return result
    }, {})

    _.forEach(this['containerTargets'], (container) => {
      const name = $(container).data('for-name')
      const values = _.filter(_.flatten([$(container).data('for-value'), $(container).data('for-values')]), undefined)
      const excludeValues = _.filter(_.flatten([$(container).data('exclude-value'), $(container).data('exclude-values')]), undefined)
      const formValues = valuesMapping[name] || []

      if (values.length > 0) {
        const targetValues = _.invokeMap(values, 'toString')
        if (_.intersection(formValues, targetValues).length > 0) {
          this.enableContainer(container)
        } else {
          this.disableContainer(container)
        }
      }

      if (excludeValues.length > 0) {
        const targetValues = _.invokeMap(excludeValues, 'toString')
        if (_.intersection(formValues, targetValues).length > 0) {
          this.disableContainer(container)
        } else {
          this.enableContainer(container)
        }
      }
    })
  }

  enableContainer(element) {
    $(element).show()
    $(element).find(':input').each(function(_, el) {
      const originalDisabled = $(el).data('originalDisabled')

      if (originalDisabled !== undefined) {
        el.disabled = originalDisabled
        $(el).removeData('originalDisabled')
        $(el).trigger('disabled')
      }
    })
  }

  disableContainer(element) {
    $(element).hide()
    $(element).find(':input').each(function(_, el) {
      const originalDisabled = $(el).data('originalDisabled')

      if (originalDisabled === undefined) {
        $(el).data('originalDisabled', el.disabled)
      }

      el.disabled = true
      $(el).trigger('disabled')
    })
  }
}
