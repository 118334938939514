import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['source', 'target']

  initialize() {
    $(this.element).find('form').submit(() => {
      $(this['targetTarget']).find('option').attr('selected', 'selected')
    })
  }

  addItems() {
    const selected = $(this['sourceTarget']).find(':selected')
    $(this['targetTarget']).append(selected)
  }

  removeItems() {
    const selected = $(this['targetTarget']).find(':selected')
    $(this['sourceTarget']).append(selected)
  }
}
