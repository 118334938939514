import { Controller } from 'stimulus'
import * as _ from 'lodash'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['questionScore', 'original', 'result']

  connect() {
    const result = this.converted()
    $(this['originalTarget']).html(result)
  }

  calc(event) {
    event.preventDefault()

    const result = this.converted(_.toNumber(event.target.value))
    $(this['resultTarget']).html(result)
  }

  converted(adjustmentRatio = 1.0) {
    const calcedRatio = 2.0 - adjustmentRatio
    if (calcedRatio <= 0) {
      return ''
    }

    const scoresData = $(this['originalTarget']).data('scoresData')
    const result = [{ ratio: [0.0, 0.3], total_count: 0.0 }, { ratio: [0.4, 0.7], total_count: 0.0 }, { ratio: [0.8, 1.0], total_count: 0.0 }]
    const questionScore = this['questionScoreTarget'].value

    _.forEach(scoresData, (data) => {
      data.adjusted_score = _.min([data.total_score * calcedRatio, questionScore])
    })

    _.forEach(result, (item) => {
      item.total_count = _.sumBy(scoresData, (data) => {
        let count = 0.0
        const scoreRate = _.round(data['adjusted_score'] / questionScore, 1)
        if (_.inRange(scoreRate, item.ratio[0], item.ratio[1]) || scoreRate === _.last(item.ratio)) {
          count = data['total_count']
        }
        return count
      })
    })

    return [
      ...result.map((item) => `<div>${this.toPercentStr(item.ratio)} 共 ${item.total_count}</div>`),
      `平均得分: ${this.calcAverage(scoresData)}`
    ]
  }

  toPercentStr(ratioArray) {
    return _.reduce(ratioArray, (first, second) => `${first * 100}% 至 ${second * 100}% `)
  }

  calcAverage(scoresData) {
    const totalCount = _.sumBy(scoresData, 'total_count')
    const totalScore = _.sumBy(scoresData, (item) => _.toNumber(item['adjusted_score']) * _.toNumber(item['total_count']))
    return _.round(totalScore / totalCount, 2)
  }
}
