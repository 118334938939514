import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['content', 'trigger']

  connect() {
    const state = $(this.element).data('state')
    if (state === 'expanded') {
      $(this['contentTargets']).removeClass('hidden')
      $(this['triggerTargets']).addClass('hidden')
    } else {
      $(this['contentTargets']).addClass('hidden')
      $(this['triggerTargets']).removeClass('hidden')
    }

    $(this['triggerTargets']).on('click', this.expand.bind(this))
  }

  expand(event) {
    event.preventDefault()
    $(this['contentTargets']).removeClass('hidden')
    $(event.target).addClass('hidden')
  }
}
