import { Controller } from 'stimulus'
import * as bootstrap from 'bootstrap'
import $ from 'jquery'

export default class extends Controller {
  modal(event) {
    const modal = new bootstrap.Modal($('#district-picker-modal'))

    event.preventDefault()
    const target = event.currentTarget
    this['targetInputGroup'] = $(target).closest('.input-group').get(0)
    modal.show(target)
  }

  pick(event) {
    const modal = new bootstrap.Modal($('#district-picker-modal'))

    event.preventDefault()
    const $contentElement = $(event.currentTarget).closest('.modal-content')
    const $districtSelect = $contentElement.find('#school_district_id')
    if (!$districtSelect.val()) {
      modal.hide()
      return
    }

    const $provinceSelect = $contentElement.find('#school_province_id')
    const $citySelect = $contentElement.find('#school_city_id')
    const label = `${$provinceSelect.find('option:selected').text()} / ${$citySelect.find('option:selected').text()} / ${$districtSelect.find('option:selected').text()}`
    const id = $districtSelect.val()
    $(this['targetInputGroup']).find('.form-control').text(label)
    $(this['targetInputGroup']).find('.form-control-input').val(id)
    modal.hide()
  }
}
