import { Controller } from 'stimulus'
import $ from 'jquery'
import * as _ from 'lodash'

export default class extends Controller {
  connect() {
    const originalText = this.element.innerHTML
    this.data.set('originalText', originalText)

    this.stop()
    this.start()
  }

  disconnect() {
    this.stop()
  }

  start() {
    const cooldown = _.toNumber(this.data.get('cooldown'))
    if (cooldown <= 0) {
      return
    }

    this.remaining(cooldown)
    this['intervalId'] = setInterval(() => this.tick(), 1000)
    this.tick()
  }

  stop() {
    if (this['intervalId']) {
      clearInterval(this['intervalId'])
      this['intervalId'] = null
    }
  }

  disable() {
    $(this.element).addClass('disabled')
    const remaining = this.data.get('remaining')
    $(this.element).html(this.data.get('loadingText'))
    $(this.element).find('span').text(remaining)
  }

  enable() {
    $(this.element).removeClass('disabled')
    $(this.element).html(this.data.get('originalText'))
  }

  tick() {
    const remaining = _.toNumber(this.data.get('remaining')) - 1
    this.remaining(remaining)
    if (remaining <= 0) {
      this.enable()
      this.stop()
    } else {
      this.disable()
    }
  }

  remaining(value) {
    this.data.set('remaining', value)
    const target = this.data.get('remainingFor')
    $(target).val(value)
  }
}
