import { Controller } from 'stimulus'
import $ from 'jquery'
import * as _ from 'lodash'

export default class extends Controller {
  static targets = ['item']

  initialize() {
    this.change = this.change.bind(this)
  }

  connect() {
    _.forEach(this['itemTargets'], (item) => {
      if (_.isEmpty($(item).data('placeholder'))) {
        $(item).data('placeholder', $(item).html())
      }
    })

    this['$form'] = $(this['itemTarget']).closest('form')
    this['$form'].on('change', this.change)
    this['$form'].trigger('change')
  }

  disconnect() {
    this['$form'].off('change', this.change)
  }

  change(event) {
    const valueArray = $(event.currentTarget).serializeArray()
    const valuesMapping = _.reduce(valueArray, function(result, input) {
      result[input.name] = result[input.name] || []
      result[input.name].push(input.value)
      return result
    }, {})

    _.forEach(this['itemTargets'], (item) => {
      const name = $(item).data('for-name')
      const value = valuesMapping[name][0]
      if (_.isEmpty(value)) {
        const placeholder = $(item).data('placeholder')
        this.changeItemValue(item, placeholder)
      } else {
        this.changeItemValue(item, value)
      }
    })
  }

  changeItemValue(item, value) {
    if ($(item).is(':input')) {
      $(item).val(value)
    } else {
      $(item).html(value)
    }
  }
}
