import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  trigger(e) {
    const $target = $(e.target)
    const url = $target.data('url')

    if (url !== undefined) {
      $['ajax']({
        url,
        method: 'get',
        dataType: 'html',
        data: { grade_id: $target.val() }
      }).then((res) => {
        const $replace = $(`${$target.data('replace')}`)
        $replace.html(res)
      })
    }
  }
}
