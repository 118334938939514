import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['item']

  toggle(event) {
    event.preventDefault()

    $(this['itemTargets']).toggleClass('hidden')
  }
}
