import { Controller } from 'stimulus'
import $ from 'jquery'
import * as qiniu from 'qiniu-js'
import * as _ from 'lodash'
import URI from '../widgets/urijs'

const uploadQiniu = function(file) {
  return new Promise((reslove, reject) => {
    const uri = URI.expand('/qiniu_upload_token')
    $['ajax']({
      url: uri,
      method: 'POST',
    }).done((data) => {
      const token = data.token
      const uploadDir = data.upload_dir

      let uploadFile = file
      const reg = /_|#|\\|\/|-|,| |\(|\)|&|@|!|\$|%|\^|\*|\+|=|\[|\]|\{|\}|'|"|<|>|\?|/g
      const fileName = file.name.replace(reg, '')
      if (fileName !== file.name) {
        uploadFile = new File([file], fileName, { type: file.type })
      }

      const key = `${uploadDir}/${fileName}`
      const observable = qiniu.upload(uploadFile, key, token, {}, {})
      observable.subscribe({
        next: (res) => {
          console.log(res.total.percent)
        },
        error: (err) => {
          reject(err)
        },
        complete: (res) => {
          reslove(res)
        }
      })
    })
  })
}

export default class extends Controller {
  static targets = ['filepicker', 'submit']

  connect() {
    const $filepickerTargets = $(this['filepickerTargets'])
    _.map($filepickerTargets, (filepickerTarget) => {
      const $filepickerTarget = $(filepickerTarget)
      const $qiniuFileElement = $filepickerTarget.closest('.qiniu-file').find('input').not(':input[type=file]')

      if (!_.isEmpty($qiniuFileElement.val())) {
        const fileFromQiniu = JSON.parse($qiniuFileElement.val())
        const fileName = fileFromQiniu.original_filename
        $filepickerTarget.closest('.custom-file').find('.custom-file-label').text(fileName)
      }
    })
  }

  selectFile(event) {
    const file = event.target.files[0]
    $(event.target).closest('.custom-file').find('.custom-file-label').text(file.name)
  }

  async submit(event) {
    event.preventDefault()
    const $filepickerTargets = $(this['filepickerTargets'])

    await Promise.all(
      _.map($filepickerTargets, async (filepickerTarget) => {
        const $filepickerTarget = $(filepickerTarget)
        const $qiniuFileElement = $filepickerTarget.closest('.qiniu-file').find('input').not(':input[type=file]')
        const multiple = $qiniuFileElement[0].getAttribute("multiple")
        const files = filepickerTarget.files
        const qiniuFileValues = []

        for (const file of files) {
          if (file) {
            const fileFromQiniu = await uploadQiniu(file)
            qiniuFileValues.push(fileFromQiniu)
          }
        }

        if (!_.isEmpty(qiniuFileValues)) {
          const value = (multiple == 'multiple' ? qiniuFileValues : qiniuFileValues[0])
          $qiniuFileElement.attr('value', JSON.stringify(value))
          $filepickerTarget.val('')
        }
      })
    )
    $(this['element']).submit()
  }
}
