import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['mark']

  append(event) {
    event.preventDefault()
    const $label = $(event.currentTarget)
    const $input = $($label.data('tmpl'))

    $input.insertBefore(this['markTarget'])
    $label.addClass('hidden')

    $input.trigger('content:loading')
    $input.trigger('content:loaded')
    setTimeout(function() {
      $input.trigger('content:ready')
    }, 0)
  }

  remove(event) {
    event.preventDefault()
    const $input = $(event.currentTarget)
    const $label = $($input.data('for'))

    $label.removeClass('hidden')
    $input.closest($input.data('target')).remove()
  }
}
